import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import vibeImage from "../images/vibe.png"

import InstaLogo from "../images/insta-logo.png"
import TikTokLogo from "../images/tiktok-logo.png"

export default function EnglishPage(props) {
  const redirect = () => {
    window.location.replace(
      `https://apps.apple.com/dk/app/vibe-party-with-your-friends/id1561154852?l=nb`
    )
  }

  return (
    <PageWrapper>
      <Wrapper>
        <BackgroundImage>
          <TitleBox>
            <Title>VIBE</Title>
            <SubTitle>party with your friends🎉</SubTitle>
          </TitleBox>
          <ButtonBox>
            <button
              style={{
                background: "none",
                border: "none",
                outline: "none",
              }}
              onClick={() => redirect()}
            >
              <Button>
                <ButtonText>download</ButtonText>
              </Button>
            </button>
          </ButtonBox>
        </BackgroundImage>
        <Boxes>
          <InstaBox>
            <a
              style={{ textDecorationLine: "none" }}
              href="https://www.instagram.com/join_vibe/"
            >
              <InstaImage />
            </a>
          </InstaBox>
          <InstaBox>
            <a
              style={{ textDecorationLine: "none" }}
              href="https://www.tiktok.com/@join_vibe_app?lang=en"
            >
              <TikTokImage />
            </a>
          </InstaBox>
        </Boxes>
      </Wrapper>
    </PageWrapper>
  )
}

// Page Wrapper
const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #121212;
`

// Content wrapper
const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  align-self: center;
  background-color: #121212;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const BackgroundImage = styled.div`
  background-image: url(${vibeImage});
  align-self: center;
  margin-top: 80px;
  background-color: #121212;
  width: 100vw;
  max-width: 600px;
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  display: flex;
  justify-content: center;
  aling-items: center;
  flex-direction: column;
`
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.p`
  font-family: Gotham;
  font-size: 55px;
  letter-spacing: 2px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  color: #fdde68;
`
const SubTitle = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 28px;
  width: 200px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex-direction: column;
`

const Button = styled.div`
  width: 170px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #fdde68;
`

const ButtonText = styled.p`
  text-align: center;
  vertical-align: middle;
  margin: 0;
  margin-bottom: 1px;
  text-decoration: none;
  color: #333;

  font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
  font-weight: bold;
  font-size: 20px;
  line-height: 50px;
`

const Boxes = styled.div`
  display: flex;
  flexdirection: row;
`

const InstaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  background-color: #121212;
  width: 100%;
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 20px;
`
const InstaImage = styled.img`
  content: url(${InstaLogo});
  flex: 1;
  width: 50px;
  height: 50px;
  object-fit: contain;
`
const TikTokImage = styled.img`
  content: url(${TikTokLogo});
  flex: 1;
  width: 70px;
  height: 70px;
  object-fit: contain;
`
